import Main from "./main/main.js"


function Home() {
  return (
    <div className="w-100">
        <Main />
    </div>
  )
}

export default Home
